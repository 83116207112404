<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showOutput="true"
			:showAdd="$hasAccess('device.group/add')" addName="新增站点" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.group_name" size="small" clearable placeholder="请按小区名称查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.city" placeholder="按城市查询" clearable size="small">
					<el-option v-for="(item,index) in $userArea" :label="item" :value="item" :key="index">
					</el-option>
				</el-select>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_name" title="站点名称" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="run_type_text" title="所属代理" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{row.run_type == 2?row.run_name:'叮当自营'}}</span>
				</template>
			</vxe-table-column>
			<!--vxe-table-column slot="table-item" field="agent_name" title="所属代理" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{row.agent_name?row.agent_name:'自营'}}</span>
				</template>
			</vxe-table-column-->
			<vxe-table-column slot="table-item" field="city" title="地区" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="allow_short_text" title="随机立减" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span v-if="row.allow_short == 10">关</span>
					<span v-if="row.allow_short == 20">开</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="refund_chat_text" title="退款至微信" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span v-if="row.refund_chat == 10" style="color:red;">关</span>
					<span v-if="row.refund_chat == 20" style="color:green">开</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cooperate_model_text" title="合作模式" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span v-if="row.cooperate_model == 10">买电</span>
					<span v-if="row.cooperate_model == 20">分成-充值充电</span>
					<span v-if="row.cooperate_model == 30">分成-充电</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status_text" title="场站状态" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span v-if="row.status == 1" style="color:green">正常</span>
					<span v-if="row.status == 2" style="color:blue;">建设中</span>
					<span v-if="row.status == 3" style="color:red;">维护中</span>
				</template>
			</vxe-table-column>
			<!-- <vxe-table-column slot="table-item" field="create_user_name" title="创建者" align="center" min-width="80px" /> -->
			<vxe-table-column slot="table-item" field="device_count" title="设备数量" align="center" min-width="60px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="plug_count" title="电枪数量" align="center" min-width="60px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="ele_price" title="电费" align="center" min-width="60px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="service_price" title="服务费" align="center" min-width="60px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="vip_price" title="会员价" align="center" min-width="60px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="discount_text" title="服务费折扣" align="center" min-width="80px">
				<template v-slot="{ row }">
					<!--          {{JSON.parse(row.discount)}}-->
					<div v-for="idx in JSON.parse(row.discount).length">
						{{JSON.parse(row.discount)[idx-1].time_slot}}-
						{{JSON.parse(row.discount)[idx-1].time_slot_2}}时
						{{JSON.parse(row.discount)[idx-1].discount}}折
					</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="parking" title="停车信息" align="center" min-width="80px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="220">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editGroup(row)" v-if="$hasAccess('device.group/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="sync(row)" v-if="$hasAccess('device.group/update')">同步
					</el-button>
					<el-button size="small" plain @click="deleteGroup(row)" v-if="$hasAccess('device.group/delete')">删除
					</el-button>
				</template>
			</vxe-table-column>
		</Tables>
	</div>
</template>

<script>
	import API from '@/service/api'
	import Tables from '@/components/tables'

	export default {
		name: 'device-group-list',
		components: {
			Tables
		},
		data() {
			return {
				tableName: '费率信息',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					plug_status: '',
					plug_num: '',
					plug_name: ''
				},
				allow_short_vals:{
					10:'关',
					20:'开'
				},
				refund_chat_vals:{
					10:'关',
					20:'开'
				},
				cooperate_model_vals:{
					10:'买电',
					20:'分成-充值充电',
					30:'分成-充电',
				},
				status_vals:{
					1:'正常',
					2:'建设中',
					3:'维护中',
				}
			}
		},
		mounted() {},
		activated() {
			this.$refs.xTable.refreshTable()
		},
		methods: {
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await API.Device.GetGroupList(params, searchData)
				this.tableData = res.data;
				for(let v of this.tableData){
					if(v.v_price) v.vip_price = v.v_price;
					v.run_type_text = v.run_type == 2?v.run_name:'叮当自营';
					v.allow_short_text = this.allow_short_vals[v.allow_short];
					v.refund_chat_text = this.refund_chat_vals[v.refund_chat];
					v.cooperate_model_text = this.cooperate_model_vals[v.cooperate_model];
					v.status_text = this.status_vals[v.status];
					let discount = JSON.parse(v.discount);
					v.discount_text = '';
					for(let d of discount){
						v.discount_text += d.time_slot + "-" + d.time_slot_2 + "时" + d.discount + "折\n"
					}
				}
				this.totalPage = res.total
				this.isLoading = false
			},
			clearSearch() {
				this.searchFormData = {
					group_name: ''
				}
			},
			addOne() {
				this.$router.push({
					name: 'device-group-add'
				})
			},
			editGroup(row) {
				this.$router.push({
					name: 'device-group-edit',
					query: {
						group_id: row.group_id
					}
				})
			},
			async sync(row) {
				const params = {
					token: this.$store.state.user.token,
					group_id: row.group_id
				}
				await API.Device.SyncGroup(params)
				this.$notify({
					title: '成功',
					message: '同步成功',
					type: 'success'
				})
			},
			deleteGroup(row) {
				this.$confirm('确定要删除该小区吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						group_id: row.group_id
					}
					await API.Device.DeleteGroup(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
